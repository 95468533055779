import isServer from "@/_base/isServer";
import axios from "axios";
import https from "https";

const config = {
	baseURL: process.env.VUE_APP_API_BASE_URL,
	loadingDuration: 1200,
}

const storage = isServer ? axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false }) : require("vue3-storage").useStorage("oright_");

const errorProcess = ({ error, vueApp }) => {
	const $swal = vueApp.config.globalProperties.$swal;
	const alertOptions = {
		title: `status: ${error.response ? error.response.status : error.name}`,
		text: error.response ? JSON.stringify(error.response.data.message) : error.message,
		icon: "error",
		allowOutsideClick: false,
		allowEscapeKey: false,
		didOpen: () => {
			$swal.hideLoading();
		},
	}

	const errorProcesses = {
		tokenError: {
			title: "登入token錯誤，請重新登入。",
			didClose: () => {
				vueApp.config.globalProperties.$store.commit("headerStore/setLoggedIn", false);
				vueApp.config.globalProperties.$router.push("/member/login");
			},
		},
		tokenExpired: {
			title: "登入過期，請重新登入。",
			icon: "warning",
			didClose: () => {
				vueApp.config.globalProperties.$store.commit("headerStore/setLoggedIn", false);
				vueApp.config.globalProperties.$router.push("/member/login");
			},
		},
	}

	let processKey;
	if (error.response) {
		switch (error.response.data.message) {
			case "The token has been blacklisted":
			case "Token Signature could not be verified.":
				processKey = "tokenError";
				break;

			case "Token has expired and can no longer be refreshed":
				processKey = "tokenExpired";
				break;

			default:
				break;
		}
	}
	return $swal.fire(processKey ? Object.assign(alertOptions, errorProcesses[processKey]) : alertOptions);
}

const setToken = (token) => {
	if (token && storage && !isServer) {
		storage.setStorageSync("token", token);
	}
}

const sendToken = (headers) => {
	if (headers && storage && !isServer) {
		const token = storage.getStorageSync("token");
		if (token) {
			headers.Authorization = token;
		}
	}
}

const refreshTokenRetry = async ({ originalRequest, vueApp }) => {
	return await axios.get(`${config.baseURL}/api/token`, {
		headers: {
			"Authorization": storage.getStorageSync("token"),
		},
	}).then(async (res) => {
		setToken(res.data.token);
		if (originalRequest) {
			originalRequest.headers.Authorization = res.data.token;
			return await axios(originalRequest);
		} else {
			return res;
		}
	}).catch(async (error) => {
		await errorProcess({ error, vueApp });
		return Promise.reject(error);
	});
}

const updatedCartIdAndBucketId = (data) => {
	if (data.user_id || data.cart_id) {
		let cartId = data.user_id || data.cart_id;
		if (storage && !isServer) {
			storage.setStorageSync("cartId", String(cartId));
		}
	}
	if (data.user_id || data.bucket_id) {
		let bucket_id = data.user_id || data.bucket_id;
		if (storage && !isServer) {
			storage.setStorageSync("bucketId", String(bucket_id));
		}
	}
}

const createAxiosInstance = (vueApp) => {
	const $swal = vueApp.config.globalProperties.$swal;
	const instance = axios.create(config);

	instance.interceptors.request.use(
		(config) => {
			const usedBaseURL = !/(http(s?)):\/\//i.test(config.url) || config.url.startsWith(process.env.VUE_APP_API_BASE_URL);
			if (usedBaseURL) {
				sendToken(config.headers);
			}
			if (config.loading) {
				config.startTime = performance.now();
				config.loadingAlert = $swal.fire({
					title: "請稍候",
					allowOutsideClick: false,
					allowEscapeKey: false,
					didOpen: () => {
						$swal.showLoading();
					}
				});
			}
			return config;
		},
		(error) => {
			console.error(error);
			return Promise.reject(error);
		}
	);

	instance.interceptors.response.use(
		async (response) => {
			setToken(response.headers.authorization || response.config.headers.token);
			updatedCartIdAndBucketId(response.data);
			if (response.data.message === "Unauthenticated." && storage && !isServer) {
				response = await refreshTokenRetry({ originalRequest: response.config, vueApp });
			}
			if (response.config.middleCall instanceof Function) {
				response.middleData = await response.config.middleCall();
			}
			if (response.config.loading) {
				response.duration = performance.now() - response.config.startTime;
				if (response.duration < response.config.loadingDuration) {
					await new Promise((resolve) => {
						setTimeout(() => {
							resolve();
							response.config.loadingAlert.close();
						}, response.config.loadingDuration - response.duration);
					});
				} else {
					await response.config.loadingAlert.close();
				}
			}
			return response;
		},
		async (error) => {
			if (isServer) {
				throw Error(`on server prefetch fail: ${error.config.baseURL}${error.config.url}`);
			}
			await errorProcess({ error, vueApp });
			return Promise.reject(error);
		}
	);

	return instance;
}

const $axios = {
	install(Vue, options) {
		Vue.config.globalProperties.axios = createAxiosInstance(Vue);
	}
}
export default $axios;